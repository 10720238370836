.toggleBox{
    margin: 0 0 12px 0;
}
.toggleNote{
    color: dimgray;
    margin: 0 0 8px !important
}
.toggleToggle{
    border: 1px solid #cdcdcd;
    box-shadow: none;
    margin: 0 0 4px 0;
}
.toggleToggle button{
    font-size: 16px !important;
}
.buttonColor{
    background-color: #00abc0;
}