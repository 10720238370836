.slideShowPad{
  margin: 0 0 0 20px;
}
.headerPage {
  width: 100%;
  background-color: #075B98;
  margin: 0 0 20px 0;
}

.headerWraper {
  width: auto;
  max-width: 1280px;
  margin: auto;
}

.searchBar {
  width: 100%;
}

.logoPan {
  margin: 0 20px 0 0;
  align-items: center;
  justify-content: center;
}

.logoPan img {
  width: 8rem;
}

.searchBox {
  align-items: center;
  justify-content: center;
  align-items: center;
  justify-content: center;
}
.moyesLogo{
  float: left;
}