.skaitemplate {
  height: 100vh;
  font-size: 1rem;
}

.headerPage {
  width: 100%;
  background-color: #00a1e4 !important;
  height: 60px;
  padding: 4px 0;
}

.headerWraper {
  width: auto;
  max-width: 1280px;
  margin: auto;
  padding-bottom: 30px;
}

.skaitemplate .container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  padding: 4rem 0;
}

.navigationBar {
  color: #FFFF;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: start;
}
.languatNav{
  float: right;
}
.languatNav ul {
  color: white;
  list-style: none;
  padding: 0;
  margin: 0;
}

.languatNav ul {
  color: white;
  list-style: none;
  float: right;
}

.languatNav ul li {
  list-style: none;
  float: left;
  cursor: pointer;
}
.languatNav ul li a {
  color: white;
  padding: 20px 12px;
  text-decoration: none;
  
}
.languatNav ul li div{
  padding: 6px 8px 6px 12px;
    border: 1px;
    margin: 5px 0 0;
}

.topIcon{
  height: 24px;
  margin: 0 4px 0 0;
}
.moyesLogoBox{
  float: left;
}
.moyesLogoBox .moyesLogo{
  height: 45px;
  float: left;
  margin: 4px 0 0 0;
}
.moyesLogoBox .moyesTitle{
  color: #FFFFFF;
  float: left;
  line-height: 52px;
  padding: 0 12px;
  font-weight: 800;
}
@media only screen and (max-width: 500px) {
  .moyesTitle {
    display: none;
    visibility: hidden;
  }
}