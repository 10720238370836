.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.marginlogo{
  margin: 4px 8px 0 0 !important;
}
.mptc{
  height: 42px !important;
  margin: 6px 0 0 0 !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.selected {
  background: hsla(0, 0%, 100%, 0.25);
  border: 1px solid hsla(0, 0%, 100%, 0.25);
}

.moyesLogo {
  height: 60px;
  margin: 0 8px 0 0;
  float: left;
}

.btn.btn-primary, .MuiButton, .search {
  background: #00a1e4 !important;
}
.search {
  width: 100%;
  height: 45px;
  margin: 0 12px;
  font-size: 1.2rem !important;
}
.search .startIcon, .search svg,
.search .css-htszrh-MuiButton-startIcon{
  font-size: 28px !important;
}
/*Form */
.form-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.form-control-placeholder {
  position: absolute;
  top: 0;
  padding: 7px 0 0 13px;
  transition: all 200ms;
  opacity: 0.5;
}

.form-control:focus+.form-control-placeholder,
.form-control:valid+.form-control-placeholder {
  font-size: 75%;
  transform: translate3d(0, -100%, 0);
  opacity: 1;
}

.formbox {
  padding: 12px 0;
  font-weight: 600;
}

.formbox > .row {
  margin: 0 0 20px 0;
}


.formbox .forminput, .forminput {
  margin: 0 0 12px 0  !important;
  width: 100%;
}
.formbox .forminput.latterNumber{
  margin-bottom: 0 !important;
}
.mask{
  color: rgba(0, 0, 0, 0.6);
}
.centerbox {
  text-align: center;
}

.loadingBox {
  width: 100%;
  float: left;
  text-align: center;
}
.downloadBox{
  width: 100%;
  float: left;
  text-align: right;
  padding: 12px 0;
}
.contentDownlod{
  margin: 0 auto;
}
.paperBox {
  padding: 30px 12px;
  float: left;
  width: 100%;
  background: #f2f2f2;
}

.paperBox .react-pdf__Page{
  min-width: 100% !important;
}

.paperBox canvas {
  height: auto !important;
  max-width: 800px;
  height: auto;

  margin: 0 auto;
  width: 100% !important;
}
.infoColor{
  color: #00aaf7;
}
.download{
  text-decoration: none;
  color: #00aaf7;
  top: 0;
  margin: 0 0 0 30px;
}
.download svg{
  margin: 0 4px 0 0;
}
button.btn{
  border: none;
}
/* pdf overide*/
.react-pdf__Page__textContent, 
.react-pdf__Page__annotations{
  visibility: hidden;
  display: none;
}
.formbox .row{
  margin: 0;
  padding: 0;
}
.Mui-selected{
  background-color: #00a1e4 !important;
  color: #FFFFFF !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}